import { useState, useRef, useEffect } from 'react';
import { parseNumber } from '../../utils/helpers';
import CountUp from 'react-countup';
import classNames from 'classnames';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import styles from './ChartBlock.module.scss';

const ChartBlock = ({ title = '', text = '', link = {}, items = [] }) => {
    if (!items.length) {
        return;
    }
    const ref = useRef(null);

    const [mounted, setMounted] = useState(false);
    const [isActiveIndex, setActiveIndex] = useState(null);
    const [clientRect, setClientRect] = useState({ top: null, left: null });
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        const isTouchDevice =
            'ontouchstart' in window || navigator.msMaxTouchPoints;

        setIsTouchDevice(isTouchDevice);
    }, []);

    const handleOnMouseMovement = (index) => {
        if (isTouchDevice) {
            return;
        }

        setActiveIndex(index);
        setClientRect({
            top: ref.current.getBoundingClientRect().top,
            left: ref.current.getBoundingClientRect().left,
        });
    };

    return (
        mounted && (
            <div ref={ref} className={styles['ChartBlock']}>
                <div className={styles['ChartBlock__Container']}>
                    <h2 className={styles['ChartBlock__Title']}>
                        <span>{title}</span>
                        <span className={styles['ChartBlock__Text']}>
                            {text}
                        </span>
                    </h2>
                    <ul className={styles['ChartBlock__List']}>
                        {items.map((item, index) => (
                            <ListItem
                                key={index}
                                index={index}
                                isActiveIndex={isActiveIndex}
                                isTouchDevice={isTouchDevice}
                                clientRect={clientRect}
                                handleOnMouseMovement={handleOnMouseMovement}
                                {...item}
                            />
                        ))}
                    </ul>
                    <div className={styles['ChartBlock__Button']}>
                        <Button
                            link={link}
                            modifiers={['tertiary', 'borderRadius']}
                        />
                    </div>
                </div>
            </div>
        )
    );
};

const ListItem = ({
    clientRect = {
        top: null,
        left: null,
    },
    isTouchDevice = false,
    isActiveIndex = null,
    index = null,
    value = '',
    link = {},
    image = {},
    handleOnMouseMovement,
}) => {
    const imageRef = useRef(null);

    const [mousePosition, setMousePosition] = useState({
        x: null,
        y: null,
    });
    const [number, setNumber] = useState(parseNumber(value));
    const { decimal, decimals, numberInt, prefix, suffix } = number;

    const classes = classNames(styles['ChartBlock__Link'], {
        [styles['ChartBlock__Link--InActive']]:
            isActiveIndex !== index && isActiveIndex !== null,
    });

    const imageClasses = classNames(styles['ChartBlock__Image'], {
        [styles['ChartBlock__Image--Visible']]:
            isActiveIndex === index &&
            mousePosition.y > 0 &&
            mousePosition.x > 0,
    });

    const handleMousePosition = (e) => {
        if (isTouchDevice) {
            return;
        }

        setMousePosition({
            x: e.nativeEvent.clientX,
            y: e.nativeEvent.clientY,
        });
    };

    const positionTop = mousePosition.y - clientRect.top - 300 / 1.5;
    const positionLeft = mousePosition.x - clientRect.left - 300 / 2;

    const style = {
        top: `${positionTop}px`,
        left: `${positionLeft}px`,
    };

    return (
        <li
            className={styles['ChartBlock__Item']}
            onMouseEnter={() => handleOnMouseMovement(index)}
            onMouseLeave={() => handleOnMouseMovement(null)}
            onMouseMove={(e) => handleMousePosition(e)}>
            <a className={classes} href={link.href}>
                <span className={styles['ChartBlock__LinkTitle']}>
                    <span className={styles['ChartBlock__Icon']}>
                        <Icon
                            icon={'arrowRight'}
                            dimensions={{ width: '32px', height: '32px' }}
                        />
                    </span>
                    {link.title}
                </span>
                <CountUp
                    start={numberInt - 0.1}
                    end={numberInt}
                    duration={1}
                    decimals={decimals}
                    decimal={decimal}
                    prefix={prefix}
                    suffix={suffix}
                    useEasing={false}
                    enableScrollSpy={true}
                    scrollSpyDelay={200}
                    scrollSpyOnce={true}>
                    {({ countUpRef }) => (
                        <span
                            ref={countUpRef}
                            className={styles['ChartBlock__Value']}
                        />
                    )}
                </CountUp>
            </a>
            <figure ref={imageRef} className={imageClasses} style={style}>
                <Image image={image} sizes={'(min-width: 1800px) 1080px'} />
            </figure>
        </li>
    );
};

ChartBlock.propTypes = {};

export default ChartBlock;
